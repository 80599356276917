<!-- 论坛-->
<template>
  <div class="">
    <ForumList :forumList="forumList" v-loading="loading"></ForumList>
    <!-- 分页 -->
    <div class="page" :style="$attrs.pageStyle || {}" v-if="total > 0">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="$attrs.isCollect?getForumList:getMyForumList" :page-size="page_size" :current-page="page" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { forum } from "@/api/me.js";
import { praise,forumList , remove} from "@/api/forum.js";
import ForumList from "@/views/forum/components/ForumList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {ForumList},
  data() {
    //这里存放数据
    return {
      page: 1,
      page_size: 5,
      loading:false,
      total:0,
      forumList: []
    };
  },
  props:['type'],
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
    if(!this.$attrs.isCollect){
      this.getMyForumList()
    }else{
      this.getForumList();
    }
  },
  //方法集合
  methods: {
    // 点击删除
    clickDetele ({ id }) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove({ id }).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!",
            })
            this.getMyForumList()
          })
        })
    },

    // 点击编辑
    clickEdit (item) {
      this.$router.push({ path: "/forum/forumEditor", query: { id: item.id } })
    },
        // 点击论坛列表某一项
    clickForum (id) {
      this.$router.push({ path: "/forum/forumSee", query: { id: id } })
    },
    getMyForumList(page) {
      this.page = page || 1;
      this.loading=true
      forumList({ page: this.page, page_size: this.page_size ,match_type:1}).then(({ data }) => {
          this.forumList = data.data;
          this.total = data.total;
        }).finally(() => {
        this.loading = false
      })
    },
    getForumList(page) {
      this.page = page || 1;
      this.loading=true
      forum({ page: this.page, page_size: this.page_size })
        .then(({ data }) => {
          this.forumList = data.data;
          this.total = data.total;
        })
       .finally(() => {
        this.loading = false
      })
    },
    // 点赞
    praise(id, index) {
      return
      praise({ id: id })
        .then((res) => {

          this.forumList[index].praise_num = res.data.praise_count;
          this.forumList[index].is_praise = res.data.is_praise;
          // this.collect = res.data.is_collect;
          this.$message.success(res.msg);
        })
        .catch((err) => {});
    },
  },
};
</script>
<style  lang='less' scoped>
 li {
    list-style-type: none;
  } 
.praisebgc {
  color: #ff623f !important;
}
  .forum {
    min-height: 150px;
    ul {
      .info_iten {
        background-color: #fff;
        margin-bottom: 20px;
        box-sizing: border-box;
        border: 1px solid #979797;
        padding: 1rem;
        // height: 97px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        .titl_box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .titl_left {
            display: flex;
          }
          .titl_right_box {
            display: flex;
            &:nth-child(1) {
              color: #f56c6c;
            }
          }
          .titl_right {
            width: 46px;
            margin-right: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #818181;
          }
          .type {
            min-width: 76px;
            white-space: nowrap;
            // padding: 0 20px;
            height: 25px;
            background: #ffffff;
            border: 2px solid #f15153;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #f15153;
            line-height: 25px;
            text-align: center;
          }
          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #3d3d3d;
            line-height: 25px;
            margin:0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .info {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .info_left {
            span {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #818181;
              line-height: 12px;
              &:nth-child(1) {
                padding-right: 12px;
                border-right: 1px solid #d2d2d2;
              }
              &:nth-child(2) {
                padding-left: 12px;
              }
            }
          }
          .info_right {
            ul {
              display: flex;
              align-items: center;
              li {
                display: flex;
                border: 0;
                .img {
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                  }
                }
                span {
                  font-size: 14px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #818181;
                  line-height: 20px;
                  margin-right: 20px;
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>