<!-- 比赛列表 -->
<template>
  <div class="">
    <div class="table-content">
      <div class="forum">
        <p class="nothing" v-if="!forumList.length">暂无数据</p>
        <ul>
          <li class="info_iten" v-for="(item, index) in forumList" :key="index" @click="clickForum(item.id)">
            <div class="titl_box">
              <div class="titl_left">
                <div class="type" v-if="item.recommend_str">
                  {{ item.recommend_str }}
                </div>
                <div class="title" v-html="item.title"></div>
              </div>
              <div class="titl_right_box" v-if="userId">
                <div class="titl_right" v-if="userInfo.id == item.user_id && item.isEdit !== false" @click.stop="clickDetele(item)">
                  <i style="color: #F56C6C" class="el-icon-delete-solid">删除</i>
                </div>
                <div class="titl_right" v-if="userInfo.id== item.user_id && item.isEdit !== false" @click.stop="clickEdit(item)">
                  <i style="color: #00957e" class="el-icon-edit">编辑</i>
                </div>
              </div>
            </div>
            <div class="info">
              <div class="info_left">
                <span>{{ item.publisher }}</span><span>{{ item._create_time }}</span>
              </div>
              <div class="info_right">
                <ul>
                  <li>
                    <div class="img">
                      <img src="../../../assets/images/me/pl@2x.png" alt="" />
                    </div>
                    <span>评论：{{ item.comment_num }}</span>
                  </li>
                  <li>
                    <div class="img">
                      <img style="width: 18px; height: 18px" :src="require('../../../assets/images/me/预览@3x.png')" alt="" />
                    </div>
                    <span>预览：{{ item.view_num }}</span>
                  </li>
                  <li @click.stop="praise(item.id, index)">
                    <div class="img">
                      <img style="width: 18px; height: 18px" :src="require('../../../assets/images/me/点赞@3x.png')" v-if="!item.is_praise" alt />
                      <img style="width: 22px; height: 22px" src="../../../assets/images/me/ydz.png" v-if="item.is_praise" alt />
                    </div>
                    <span :class="{ praisebgc: item.is_praise }">点赞：{{ item.praise_num }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { praise, remove } from "@/api/forum.js"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    forumList: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    //这里存放数据
    return {
      showTeam: false,
      types: localStorage.getItem("type"),
      currentPage2: 5,
      userId:0
    }
  },
    
  created(){
    this.userId=localStorage.getItem('user_id')
    console.log(this.userInfo.id,"-----------------")
    console.log(this.userId,"========================")
  },
  //方法集合
  methods: {
    // 点击我的团队
    clickMeTeam () {
      if (!localStorage.getItem("user_token")) {
        return (this.$store.state.lodinShow = true)
      }

      let obj = {}
      this.showTeam = !this.showTeam
      if (this.types == 2) {
        obj.match_type = 1
      }
      if (this.types == 1) {
        obj.match_type = 2
      }
      if (this.showTeam) {
        this.$parent.getForumList(obj)
      } else {
        this.$parent.getForumList()

      }
    },
    // 点击删除
    clickDetele ({ id }) {
    
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove({ id }).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!",
            })
            this.$parent.getForumList()
          })
        })
    },

    // 点击编辑
    clickEdit (item) {
      console.log(item)
      this.$router.push({ path: "/forum/forumEditor", query: { id: item.id } })
    },
    // 点赞
    praise (id, index) {
      praise({ id: id })
        .then((res) => {
          console.log(res)

          this.forumList[index].praise_num = res.data.praise_count
          this.forumList[index].is_praise = res.data.is_praise
          // this.collect = res.data.is_collect;
          this.$message.success(res.msg)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onEditorChange ({ editor, html, text }) {
      console.log(editor, html, text)
      //获取文本值
      // html带html标签
      //text只有文字
    },

    //   点击创建
    clickPost () {
      this.$router.push("/forum/forumEditor")
    },
    // 点击论坛列表某一项
    clickForum (id) {
      this.$router.push({ path: "/forum/forumSee", query: { id: id } })
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
};
</script>
<style  lang='less' scoped >
 li {
    list-style-type: none;
  } 
.nothing {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
  text-align: center;
  margin-top: 2%;
}
.myTeam {
  background-color: #fff;
  border: 1px solid #00957e;
  color: #00957e;
  height: 24px;
  // box-sizing: border-box;
}
.praisebgc {
  color: #ff623f !important;
}
.operate {
  margin: 2.2% 0 1.8%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .new_problemList {
    margin-left: 1rem;
  }
}
.table-content {
  // background-color: #fff;
  .forum {
    min-height: 150px;
    ul {
      .info_iten {
        background-color: #fff;
        margin-bottom: 20px;
        box-sizing: border-box;
        border: 1px solid #979797;
        padding: 1rem;
        // height: 97px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        .titl_box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .titl_left {
            display: flex;
          }
          .titl_right_box {
            display: flex;
            &:nth-child(1) {
              color: #f56c6c;
            }
          }
          .titl_right {
            width: 46px;
            margin-right: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #818181;
          }
          .type {
            min-width: 76px;
            white-space: nowrap;
            // padding: 0 20px;
            height: 25px;
            background: #ffffff;
            border: 2px solid #f15153;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #f15153;
            line-height: 25px;
            text-align: center;
          }
          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #3d3d3d;
            line-height: 25px;
            margin:0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .info {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .info_left {
            span {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #818181;
              line-height: 12px;
              &:nth-child(1) {
                padding-right: 12px;
                border-right: 1px solid #d2d2d2;
              }
              &:nth-child(2) {
                padding-left: 12px;
              }
            }
          }
          .info_right {
            ul {
              display: flex;
              align-items: center;
              li {
                display: flex;
                border: 0;
                .img {
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                  }
                }
                span {
                  font-size: 14px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #818181;
                  line-height: 20px;
                  margin-right: 20px;
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.page {
  display: flex;
  justify-content: center;
  margin: 3% 0 1%;
}

// @import '../../../assets/css/bgc.css'
</style>