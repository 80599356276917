import request from "@/Utils/api";
// 分类列表
export function typeList(data){
    return request({
        url: "api/v1_0_0.forum/types",
        method: "post",
        cache:true,
        data,
      });
}
// 帖子列表
export function forumList(data){
    return request({
        url: "api/v1_0_0.forum",
        method: "post",
        data,
      });
}
// 帖子详情
export function forumDetail(data){
    return request({
        url: "api/v1_0_0.forum/detail",
        method: "post",
        data,
      });
}
// 评论列表
export function comment_list(data){
  return request({
      url: "api/v1_0_0.forum/comment_list",
      method: "post",
      data,
    });
}
// 回复评论
export function replyComment_list(data){
  return request({
      url: "api/v1_0_0.forum/comment_submit",
      method: "post",
      data,
    });
}
// 帖子收藏
export function collect(data){
  return request({
      url: "api/v1_0_0.forum/collect",
      method: "post",
      data,
    });
}

// 帖子点赞
export function praise(data){
  return request({
      url: "api/v1_0_0.forum/praise",
      method: "post",
      data,
    });
}
// 发布论坛
export function submit(data){
  return request({
      url: "api/v1_0_0.forum/submit",
      method: "post",
      data,
    });
}

// 编辑论坛
export function edit(data){
  return request({
      url: "api/v1_0_0.forum/edit",
      method: "post",
      data,
    });
}
// 推荐帖子阅读
export function recommend(data){
  return request({
      url: "api/v1_0_0.forum/recommend",
      method: "post",
      data,
    });
}

// 公告列表
export function notice(data){
  return request({
      url: "api/v1_0_0.forum/msg_list",
      method: "post",
      data,
    });
}

// 删除帖子
export function remove(data){
  return request({
      url: "api/v1_0_0.forum/remove",
      method: "post",
      data,
    });
}








